.turbine-loading {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.turbine-loading:not(.bg-white) {
  background-color: #8693ab;
  background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
}
