.contact-row {
  display: flex;
  font-size: 1.125rem;
}

.contact-label {
  min-width: 105px;
  text-align: right;
}

.edit-user-details-form label {
  font-size: 1rem;
  font-weight: bold;
}
