.modal-enrollExternalRegisteredClass .modal-content {
  padding: 0 !important;
}

.modal-enrollExternalRegisteredClass .cohort-container {
  position: relative;
}

.modal-enrollExternalRegisteredClass .cohort-container:hover {
  border: 1px #0984e3 solid !important;
}

