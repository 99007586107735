@import url(./colors.css);
@import url(./cards.css);
@import url(./buttons.css);
@import url(./forms.css);
@import url(./modals.css);
@import url(./tables.css);
@import url(./demoMode.css);

.border-primary-light {
  border-color: #b2ccf6 !important;
}

.shadow-primary-light {
  box-shadow: 0 0.5rem 1rem rgba(0, 123, 255, 0.2) !important;
}
