.main-content p {
  font-size: 1.25rem;
}

.main-content img {
  max-width: 100%;
  margin: 0.5rem;
}

.main-content img[alt="box-model-standard-small-2"],
.main-content img[alt="box-model-alt-small"] {
  border: 0;
}

.main-content h1, .main-content h2, .main-content h3,
.main-content h4, .main-content h5, .main-content h6 {
  font-weight: bold;
}

.main-content code {
  background: #f7f7f9;
  border: 1px #e1e1e8 solid;
  border-radius: 2px;
  padding: 3px 7px;
}

.main-content pre {
  background: #f1f1f1 !important;
  border: 0 solid var(--ships-officer);
  font-size: 100%;
  overflow: hidden !important;
  overflow-x: scroll !important;
  padding: 10px !important;
}

.main-content pre code {
  background: none;
  border: 0;
  padding: 0;
}

.main-content pre.bg-none {
  background: none !important;
  padding: 0 !important;
}

.main-content pre a {
  cursor: pointer;
}

.main-content dd {
  margin-left: 1rem;
}

.main-content blockquote {
  border: 0 solid var(--green-grey);
  border-width: 0 0 0 3px;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-left: 0.5rem;
  max-width: 42rem;
  padding: 20px;
}

.main-content li {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.main-content table {
  color: var(--ships-officer) !important;
  font-size: 1rem;
}

.main-content table td,
.main-content table th {
  border: 1px solid #dee2e6;
  padding: 8px 14px;
}

.main-content table thead td,
.main-content table thead th {
  border-bottom-width: 2px;
}

.main-content table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.main-content .class-content table {
  border: 1px solid #dee2e6 !important;
}

.main-content .class-content table thead {
  background: #3f5365 !important;
  color: white;
}
