.class-dashboard-container .card-content h1,
.class-dashboard-container .card-content h2,
.class-dashboard-container .card-content h3,
.class-dashboard-container .card-content h4,
.class-dashboard-container .card-content h5,
.class-dashboard-container .card-content h6 {
  font-weight: bold;
}

.class-dashboard-container .course-dashboard-card .mb-4 {
  margin-bottom: 0 !important;
}

@media (min-width: 768px) {
  .class-dashboard-container .go-live-slim-banner-container {
    display: none !important;
 }
}

.externalCourseUrlBtn,
.internalCourseBtn,
.comingSoonCourseBtn,
.costEnrollCourseBtn,
.noCostEnrollCourseBtn,
.registerCourseBtn,
.disabledCourseBtn,
.courseBtn {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .externalCourseUrlBtn,
  .internalCourseBtn,
  .comingSoonCourseBtn,
  .costEnrollCourseBtn,
  .noCostEnrollCourseBtn,
  .registerCourseBtn,
  .disabledCourseBtn,
  .courseBtn {
    font-size: 1.25rem;
 }
}
