.navbar-brand {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-transform: capitalize;
}

.navbar-brand.cursor-none {
  cursor: default;
}
