.SessionAttendanceCheckIn-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.SessionAttendanceCheckIn-container .label {
  font-size: 1rem;
  font-weight: bold;
}
