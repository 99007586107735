.ribbon-wrapper {
  height: 138px;
  overflow: hidden;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 125px;
}

@media (min-width: 768px) {
  .ribbon-wrapper.ribbon-class-dashboard {
    display: none !important;
  }
}

.ribbon {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  font: bold 18px Sans-Serif;
  left: 4px;
  padding: 7px 0;
  position: relative;
  text-align: center;
  text-shadow: rgba(255, 255, 255, .5) 0 1px 0;
  top: 24px;
  transform: rotate(45deg);
  width: 158px;
}

.ribbon::after, .ribbon::before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #6e8900;
  bottom: -3px;
  content: '';
  position: absolute;
}

.ribbon::before {
  left: 0;
}

.ribbon::after {
  right: 0;
}
