.card-dashboard--profile .card-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.card-dashboard--profile .card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
