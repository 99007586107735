.authpage-logo {
  height: 50px;
}

@media (min-width: 768px) {
  .authpage-logo {
    height: 75px;
  }
}

.authpage-logo img {
  height: 50px;
}

@media (min-width: 768px) {
  .authpage-logo img {
    height: 70px;
  }
}

