a, .btn-link {
  color: var(--electron-blue);
  background: none;
  border: 0;
}

a.loading, button.loading, .btn.loading {
  pointer-events: none;
  cursor: default;
}

a[disabled], button[disabled], .btn[disabled] {
  pointer-events: none;
}

a.dropdown-toggle-split, button.dropdown-toggle-split, .btn.dropdown-toggle-split {
  border-left: 1px rgba(255, 255, 255, .3) solid !important;
}

.btn-primary {
  background-color: var(--electron-blue);
  border-color: var(--electron-blue);
  color: white !important;
}

.btn-primary:hover {
  background-color: rgba(0, 0, 0, );
  border-color: var(--electron-blue);
}

.btn-primary:disabled {
  background-color: rgba(0, 0, 0, );
  border-color: rgba(0, 0, 0, );
  cursor: progress;
}

.btn-white {
  color: var(--ships-officer);
  background-color: white;
  border-color: #d2d2d2;
}

.btn-white:hover {
  background-color: #f8f9fa;
  border-color: var(--ships-officer);
}

.btn-emphasis-primary {
  background-color: var(--georgia-peach);
  border-color: var(--georgia-peach);
  font-family: "Roboto", Sans-serif;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
}

.btn-emphasis-primary:hover {
  background-color: rgba(0, 1, 0, );
  border-color: var(--georgia-peach);
  color: white;
}

.btn-emphasis-primary:disabled {
  background-color: var(--green-grey);
  border-color: var(--green-grey);
  cursor: progress;
}

.btn-outline-primary {
  color: var(--electron-blue);
  border-color: var(--electron-blue);
}

.btn-outline-primary:hover {
  background-color: var(--electron-blue);
  border-color: var(--electron-blue);
}

.btn-outline-info[aria-expanded="true"] {
  background-color: #17a2b8;
  color: white;
}

.btn-outline-secondary[aria-expanded="true"] {
  background-color: #6c757d;
  color: white;
}