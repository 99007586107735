.cert-data {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
  white-space: nowrap;
  margin-bottom: 2px;
}

@media (min-width: 576px) {
  .cert-data {
    max-width: initial;
 }
}
