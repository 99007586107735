.checkbox {
  font-weight: 400;
}

.form-control {
  box-sizing: border-box;
  font-size: 16px;
  height: auto;
  padding: 10px;
  position: relative;
}

.form-control:focus {
  z-index: 2;
}

.class-content .sidebar {
  border-left: 1px #dfe0e1 solid;
}

.breadcrumb-item {
  color: rgba(44, 58, 71, 0.7);
}

.breadcrumb-item a {
  color: #0056b3;
}

.breadcrumb-item.active {
  color: black;
}

.breadcrumb-container {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.breadcrumb-container .breadcrumb {
  margin: 0;
  padding: 0;
}

.breadcrumb-container .breadcrumb ol {
  padding: 0;
}
