.navbar-container {
  display: flex;
  justify-content: space-between;
}

.nav-icon {
  height: 38px;
}

.navbar {
  z-index: 2;
  box-shadow: 0 0 8px rgba(0, 0, 0, .15) !important;
  z-index: 7;
}

.navbar .nav-link {
  cursor: pointer;
}

.navbar .top-nav-trigger {
  padding: 0.75rem 1rem;
}

.navbar .form-control {
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem 1rem;
}

.navbar .dropdown-menu {
  cursor: pointer;
  position: absolute;
  min-width: 300px;
}

.user-profile img {
  border: 1px solid var(--ships-officer);
  border-radius: 50%;
  margin-right: 10px;
  height: 38px;
  width: 38px;
}

.topNav-icon-dropdown-btn {
  background: none !important;
  border: 0;
  color: var(--ships-officer);
  font-size: 1.15rem;
}

.topNav-icon-dropdown-btn[aria-expanded=true] {
  color: var(--electron-blue);
}

.notifications-dropdown {
  right: -93px;
  width: 100vw;
}

@media (min-width: 768px) {
  .notifications-dropdown {
    min-width: 700px !important;
    width: auto;
    right: 0;
  }
}

#dropdownWeather[aria-expanded=true] {
  background: var(--ships-officer) !important;
  color: white;
}