.completion-certificate body {
  background: url("https://turbine-content.s3.us-east-2.amazonaws.com/certificate-watermark.jpg");
}


@media screen {
  .completion-certificate [role="main"] {
    max-width: 768px;
    margin: 0 auto;
  }

}


#trust-certificate-container {
  min-width: 768px;
}

#trust-certificate {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 970px;
}

#trust-certificate-data {
  position: absolute;
  left: 50px;
  top: 280px;
}

#trust-certificate-data div {
  font-size: 1rem;
  margin-bottom: 2.5rem;
  color: #1e1e1e;
}

.watermark-id {
  font-size: 1.5rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  opacity: 0.125;
}

@media print {
  .hide-when-printing {
    display: none;
  }
}