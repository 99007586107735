.widget-listView-title {
  line-height: 1rem;
  margin-bottom: .25rem;
}

.widget-listView-body {
  font-size: .75rem;
  line-height: .75rem;
}


/* @media (min-width: 768px) {
  .widget-listView-title {
    line-height: 1.25rem;
    margin-bottom: .5rem;
  }

  .widget-listView-body {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 1rem;
  }
} */