.class-card-container .card-header {
  background: none;
  min-height: 3rem;
}

.class-card-container .card-text img {
  width: 100%;
}

.coming-soon {
  cursor: default !important;
}
