.admin-menu-link i {
  margin-right: 0 !important;
}

.admin-menu-link:hover {
  background-color: rgba(0, 0, 0, ) !important;
}

.leftnav-topic .dropdown .admin-menu-link, .nav-sidenav-sub_list .dropdown .admin-menu-link {
  opacity: 0;
}

.leftnav-topic .dropdown.show .admin-menu-link, .nav-sidenav-sub_list .dropdown.show .admin-menu-link {
  opacity: 1;
}

.leftnav-topic:hover .admin-menu-link, .nav-sidenav-sub_list li:hover .admin-menu-link {
  opacity: 1;
}

.card-header.bg-dark, .card-header.bg-secondary {
  position: relative;
}

.card-header.bg-dark .dropdown, .card-header.bg-secondary .dropdown {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.card-header.bg-dark .admin-menu-link, .card-header.bg-secondary .admin-menu-link {
  color: white;
  height: 100%;
  padding: 0 0.85rem;
}
