* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

body {
  color: #3f5366;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  min-width: 330px;
}

.blur-elem {
  filter: blur(4px);
}

p {
  font-size: 1.125rem;
}

small p {
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);
}

.feather {
  height: 16px;
  vertical-align: text-bottom;
  width: 16px;
}

.opacity-0 {
  opacity: 0;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-muted a {
  color: #1b9cfc;
}

.text-light {
  color: #ccc !important;
}

.opacity-50 {
  opacity: 50%;
}

.pointer-events-none {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.bg-dark {
  background: var(--ships-officer) !important;
}