.user-invite-container {
  justify-content: center;
  width: 100%;
  max-width: 980px;
}

@media (max-width: 768px) {
  .user-invite-container {
    align-items: center;
    height: 100%;
    margin-left: initial;
 }
}

.user-invite-container .card {
  height: auto;
}

@media (min-width: 768px) {
  .has-additional-content .card {
    height: 100% !important;
 }
}

@media (min-width: 768px) {
  .has-additional-content .auth-container {
    margin-top: 0;
    margin-left: 0;
 }
}

.course-video-container {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 768px) {
  .course-video-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
 }
}

.course-video-container .embed-container {
  height: 100%;
}

.course-video-container .embed-container .turbine-loading {
  height: 100%;
  color: black !important;
  background: none;
  font-size: 1.5rem;
}
