
.classTitle-responsive {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 360px) {
  .classTitle-responsive {
    max-width: 140px;
 }
}

@media (min-width: 375px) {
  .classTitle-responsive {
    max-width: 160px;
 }
}

@media (min-width: 400px) {
  .classTitle-responsive {
    max-width: 180px;
 }
}

@media (min-width: 420px) {
  .classTitle-responsive {
    max-width: 200px;
 }
}

@media (min-width: 440px) {
  .classTitle-responsive {
    max-width: 220px;
 }
}

@media (min-width: 460px) {
  .classTitle-responsive {
    max-width: 240px;
 }
}

@media (min-width: 480px) {
  .classTitle-responsive {
    max-width: 260px;
 }
}

@media (min-width: 500px) {
  .classTitle-responsive {
    max-width: 280px;
 }
}

@media (min-width: 520px) {
  .classTitle-responsive {
    max-width: 300px;
 }
}

@media (min-width: 540px) {
  .classTitle-responsive {
    max-width: 320px;
 }
}

@media (min-width: 600px) {
  .classTitle-responsive {
    max-width: none;
 }
}
