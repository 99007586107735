/* .sidebar {
  bottom: 0;
  border-bottom: 1px #dfe0e1 solid;
  border-radius: 0 0 5px 5px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  left: 0;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  top: 0;
  z-index: 6;
  position: relative;
} */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  padding: var(--navbar-height) 0 0;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 148px);
  /* padding-top: 0.5rem; */
  position: sticky;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-sidenav-list:first-child {
  border-top: 0 !important;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar .btn-link {
  border-radius: initial;
  display: block;
  text-align: left;
  width: 100%;
}

.nav-sidenav-list {
  border-bottom: 1px solid #b1b7bc;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.nav-sidenav-list>li {
  background-color: transparent;
}

.nav-sidenav-list li {
  border-top: 1px solid #b1b7bc;
  font-size: 1.7rem;
  margin-bottom: 0;
  width: 100%;
}

.nav-sidenav-list li:first-child {
  border-top: 0;
}

.nav-sidenav-list .btn-link {
  border: 0;
  color: var(--ships-officer);
  display: block;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  line-height: 1.3;
  padding: 0.85rem 1rem;
  text-decoration: none;
}

.nav-sidenav-list .btn-link:hover {
  background-color: var(--grey-light);
  color: var(--ships-officer);
  text-decoration: none;
}

.nav-sidenav-list .btn-link:focus {
  outline-offset: 0;
  position: relative;
  z-index: 1;
}

.nav-sidenav-list .btn-link.nav-current {
  border-left: 0.4rem solid var(--keppel);
  color: var(--ships-officer);
  font-weight: 700;
}

@media screen and (min-width: 1201px) {
  .nav-sidenav-list {
    border-bottom: 0;
    border-top: 0;
  }
}

.nav-sidenav-sub_list {
  background: rgba(0, 0, 0, .01);
  box-shadow: -1px 2px 5px rgba(0, 0, 0, .2) inset;
  list-style-type: none;
  margin: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  width: 100%;
}

.nav-sidenav-sub_list>li {
  border: 0;
  border-bottom: 1px solid #dedede;
  font-size: 1.5rem;
  display: flex;
}

.nav-sidenav-sub_list>li:last-child {
  border-bottom: 0 !important;
}

.nav-sidenav-sub_list .btn-link {
  line-height: 1.3;
  padding-left: 1rem;
}

.nav-sidenav-sub_list .btn-link:hover,
.nav-sidenav-sub_list .btn-link.nav-current {
  border: 0;
}

.nav-sidenav-sub_list .btn-link:focus {
  outline-offset: 0;
}

.nav-sidenav-sub_list .btn-link:hover {
  background-color: rgba(0, 0, 0, .03);
}

.nav-sidenav-sub_list .btn-link i {
  margin-right: 0.25rem;
}

.leftnav-topic-btn {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.leftnav-topic-btn .topic-not-complete-icon {
  opacity: 0.4;
}