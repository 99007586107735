#students-only main {
  padding-top: 0;
}

#students-only thead th {
  border-top: 0 !important;
}

#students-only p {
  font-size: 1.125rem;
}
